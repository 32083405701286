import React, { useEffect, useState } from 'react';
import QRCode from 'react-qr-code';

const Modal = ({open, setOpen, qrCode, value}) => {
    const handleSetOpen = () => {
        setOpen(false);
    };
    const [qr, setQr] = useState(null);

    useEffect(() => {
            setQr(qrCode);
    }, [qrCode]);

    return (
        <div
            className={`fixed inset-0 max-w-sm mx-auto flex items-center justify-center z-50 transition-shadow delay-200 ${
                open ? 'visible' : 'hidden'
            }`}>
            <div className='fixed inset-0 bg-gray-900 opacity-70'></div>
            <div className='fixed flex flex-col bg-white w-[300px] h-fit shadow-2xl shadow-gray-800 flex justify-center items-center p-6 rounded-lg'>
                <p className='my-3 text-3xl font-semibold text-center'>mashayyik</p>
                {qr && (
                    <div>
                        <p className='text-center text-gray-700 text-sm mb-2'>Scan QR Code to pay</p>
                        <QRCode value={qr} size={250} />
                        <p className='my-2 text-3xl font-bold text-center'>Rp. {value}</p>
                    </div>
                )}
                <button className='text-xl my-3 bg-red-700 px-2 py-1 rounded-lg text-white' onClick={handleSetOpen}>
                    CLOSE
                </button>
            </div>
        </div>
    );
};

export default Modal;
