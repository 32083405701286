function ConvertCRC16(str) {
    function charCodeAt(str, i) {
        return str.charCodeAt(i);
    }
    let crc = 0xffff;
    let strlen = str.length;
    for (let c = 0; c < strlen; c++) {
        crc ^= charCodeAt(str, c) << 8;
        for (let i = 0; i < 8; i++) {
            if (crc & 0x8000) {
                crc = (crc << 1) ^ 0x1021;
            } else {
                crc = crc << 1;
            }
        }
    }
    let hex = crc & 0xffff;
    hex = hex.toString(16).toUpperCase();
    if (hex.length == 3) hex = '0' + hex;
    return hex;
}
let nama_baru = 'mashayyik';
let kota_baru = 'NewYork USA';
let qris =
    '00020101021126570011ID.DANA.WWW011893600915344359189002094435918900303UMI51440014ID.CO.QRIS.WWW0215ID10232623410690303UMI5204723053033605802ID5909' +
    nama_baru +
    '6011' +
    kota_baru +
    '6105251736304FFA0';

const generateQris = (qty) => {
    if (qty == 0)
        return '00020101021126570011ID.DANA.WWW011893600915344359189002094435918900303UMI51440014ID.CO.QRIS.WWW0215ID10232623410690303UMI5204723053033605802ID5909HLR STORE6011Kota Padang6105251736304FFA0';
    else {
        qris = qris.slice(0, -4);
        let step1 = qris.replace('010211', '010212');
        let step2 = step1.split('5802ID');
        let uang = '54' + ('0' + qty.length).slice(-2) + qty;
        uang += '5802ID';
        let fix = step2[0].trim() + uang + step2[1].trim();
        fix += ConvertCRC16(fix);
        console.log(fix);
        return fix;
    }
};

export default generateQris;
