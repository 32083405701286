
import React, { useState } from 'react';
import Modal from './Modal';
import axios from 'axios';
import generateQris from './generate';

const ValueButton = ({ value, onClick }) => {
    return (
        <button className='px-3 py-2 bg-gray-800 text-[10px] text-white font-semibold rounded-md hover:bg-gray-500' onClick={() => onClick(value)}>
            {value}
        </button>
    );
};


const StepperInput = ({ setLoading }) => {
    const [value, setValue] = useState("0");
    const [open, setOpen] = useState(false);
    const [qrCode, setQrCode] = useState(null);

    const decreaseValue = () => {
        if (value > 0) {
            setValue((Number(value) - 1000).toString());
        }
    };

    const increaseValue = () => {
        setValue((Number(value)+ 1000).toString());
    };

    const handleClick = (value) => {
        setValue(value);
    };

    const changeValue = (e) => {
        setValue(e.target.value);
    };

    const handleGenerate = async () => {
        setLoading(true);
        try {
            const data = generateQris(value);
            setQrCode(data);
            setOpen(true);
            console.log(data);
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className='flex flex-col gap-5'>
            <div className='flex items-center shadow-md shadow-gray-500'>
                <button className='px-4 py-2 bg-gray-800 text-white font-semibold rounded-l hover:bg-gray-500' onClick={decreaseValue}>
                    -
                </button>
                <input type='text' className='px-4 py-2 bg-gray-200 text-red-700 font-semibold text-center' value={value} onChange={changeValue} />
                <button className='px-4 py-2 bg-gray-800 text-white font-semibold rounded-r hover:bg-gray-500' onClick={increaseValue}>
                    +
                </button>
            </div>
            <div className='flex gap-2 justify-between'>
                <ValueButton value={"5000"} onClick={handleClick} />
                <ValueButton value={"8000"} onClick={handleClick} />
                <ValueButton value={"10000"} onClick={handleClick} />
                <ValueButton value={"15000"} onClick={handleClick} />
                <ValueButton value={"20000"} onClick={handleClick} />
            </div>
            <div>
                <p className='text-[10px] italic'>*nominal yang tersedia</p>
            </div>
            <div className='flex mx-auto w-full mt-10'>
                <button className='px-4 w-full py-2 bg-red-700 text-white font-semibold rounded-md hover:bg-red-500' onClick={handleGenerate}>
                    GENERATE
                </button>
            </div>
            <Modal open={open} setOpen={setOpen} qrCode={qrCode} value={value} />
        </div>
    );
};

export default StepperInput;